<div class="container-box">
  <div class="dashboard-child-container no-padding list">
    <!-- <div class="sum">
    <div *ngFor="let tab of tabs; let i = index;" class="sum-item sum-item-{{getColorIndex(i)}}" >
      <div class="count">{{countForTab(tab)}}</div>
      <div class="unit">Shipments</div>
      <div class="desc">{{tab.name}}</div>
    </div>
  </div> -->

  <nz-tabset [(nzSelectedIndex)]="tabIndex" (nzSelectChange)="onTabChange($event)">
    <nz-tab  *ngFor="let tab of tabs; let i = index;" [nzTitle]="getTabLabel(tab)">
      <ng-template nz-tab>
        <div *ngIf="tab.key != 'needAppointment'" #listShipment shipment-queue-list-shipments
          [apiPath]="tab.apiPath"
          [isVisible]="i == tabIndex"
          (sumDataUpdated)="onSumDataUpdated(tab, $event)">
        </div>
        <div *ngIf="tab.key == 'needAppointment'" shipment-queue-list-need-appointments
          [apiPath]="tab.apiPath"
          [isVisible]="i == tabIndex"
          (sumDataUpdated)="onSumDataUpdated(tab, $event)">
        </div>
      </ng-template>
    </nz-tab>
  </nz-tabset>
  </div>
</div>
